import React, { useEffect, useState } from 'react';
import { Button, Dialog, DialogTitle, Box, TableRow, Table, TableCell, Typography, Tabs, Tab } from '@mui/material';
import Close from '@mui/icons-material/Close';
import { useCookies } from 'react-cookie';

const OUTPUT = {
  Percentages: 0,
  'Market Baselines': 1,
};

const BaselineModal = () => {
  const [open, setOpen] = useState(false);
  const [data, setData] = useState();
  const [cookies] = useCookies(['passwordUsed', 'userType']);
  const [tabOutput, setTabOutput] = useState(OUTPUT.Percentages);
  useEffect(() => {
    fetch(`api/baselines/${cookies.userType}/${cookies.passwordUsed}`, { include: 'credentials' })
      .then((res) => res.json())
      .then((data) => setData(data));
  }, []);

  const getValue = (value) => {
    if (!value) return '--';
    const rounded = (value * 100).toFixed(1);
    return rounded;
  };

  if (!data) return null;
  return (
    <div className="width100 calculate-pay" style={{ textAlign: 'center' }}>
      <Button
        sx={{
          textTransform: 'none',
          width: '100%',
          color: '#f9f9f9',
          border: 'solid 2px #E0DED4',
          bgColor: '#111111',
          mt: 2,
        }}
        variant="contained"
        onClick={() => setOpen(true)}
        className="width45"
        size="large"
      >
        Baselines and Percentages
      </Button>
      <Dialog onClose={() => setOpen(false)} open={open} maxWidth="md" fullWidth>
        <Box sx={{ bgcolor: 'black', border: `1px solid #eee` }} p={2}>
          <Box mb={2} display="flex" alignItems="center" justifyContent="space-between">
            <Tabs
              value={tabOutput}
              onChange={(event, newValue) => {
                setTabOutput(newValue);
              }}
              sx={{
                '& .MuiTabs-indicator': {
                  bgcolor: '#a0f01c',
                },
                '& .MuiTab-root': {
                  color: 'white',
                  textTransform: 'none',
                },
                '& .MuiTab-root.Mui-selected': {
                  color: '#a0f01c',
                },
              }}
            >
              <Tab label="Percentages" />
              <Tab label="Market Baselines" />
            </Tabs>
            <Close
              sx={{ color: 'white', cursor: 'pointer' }}
              onClick={() => {
                setOpen(false);
              }}
            />
          </Box>
          <Box
            sx={{
              overflowX: 'auto',
              width: '100%',
            }}
          >
            {tabOutput === OUTPUT.Percentages && (
              <Table
                size="small"
                sx={{
                  color: 'white',
                  '& .MuiTableCell-root': {
                    bgcolor: '#111',
                    color: 'white',
                  },
                  '& .MuiTableRow-root:nth-of-type(6) td, & .MuiTableRow-root:nth-of-type(6) th': {
                    bgcolor: '#111 !important',
                    color: 'white !important',
                  },
                }}
              >
                <TableRow>
                  <TableCell>Lead Type</TableCell>
                  <TableCell>L Split</TableCell>
                  <TableCell>X Split</TableCell>
                  {data.percentages.some((x) => !!x['X Override Per Watt']) && <TableCell>X Override Per Watt</TableCell>}
                </TableRow>
                {data.percentages.map((pct) => (
                  <TableRow key={pct['Lead Type']}>
                    <TableCell>{pct['Lead Type']}</TableCell>
                    <TableCell>{getValue(pct['L Split'])}%</TableCell>
                    <TableCell>{getValue(pct['X Split'])}%</TableCell>
                    {data.percentages.some((x) => !!x['X Override Per Watt']) && (
                      <TableCell>{pct['X Override Per Watt'] ? '$' + Number(pct['X Override Per Watt']).toFixed(3) : '--'}</TableCell>
                    )}
                  </TableRow>
                ))}
              </Table>
            )}
            {tabOutput === OUTPUT['Market Baselines'] && (
              <Table
                size="small"
                sx={{
                  color: 'white',
                  '& .MuiTableCell-root': {
                    bgcolor: '#111',
                    color: 'white',
                  },
                  '& .MuiTableRow-root:nth-of-type(6) td, & .MuiTableRow-root:nth-of-type(6) th': {
                    bgcolor: '#111 !important',
                    color: 'white !important',
                  },
                }}
              >
                <TableRow>
                  <TableCell>Channel</TableCell>
                  <TableCell>State</TableCell>
                  <TableCell>Cash or Loan Baseline</TableCell>
                  <TableCell>Sunrun TPO Baseline</TableCell>
                  <TableCell>Enfin TPO Baseline</TableCell>
                  <TableCell>Everbright TPO Baseline</TableCell>
                  <TableCell>Utility Company for Enfin TPO</TableCell>
                  <TableCell>Sunnova TPO Baseline</TableCell>
                </TableRow>
                {data.baselines.map((b) => (
                  <TableRow key={b['Channel'] + b['State']}>
                    <TableCell>{b['Channel'] === 'Y' ? 'L' : b['Channel']}</TableCell>
                    <TableCell>{b['State']}</TableCell>
                    <TableCell>${Number(b['Cash or Loan Baseline']).toFixed(2)}</TableCell>
                    <TableCell>{b['Sunrun TPO Baseline'] ? '$' + Number(b['Sunrun TPO Baseline']).toFixed(2) : '--'}</TableCell>
                    <TableCell>{b['Enfin TPO Baseline'] ? '$' + Number(b['Enfin TPO Baseline']).toFixed(2) : '--'}</TableCell>
                    <TableCell>{b['Everbright TPO Baseline'] ? '$' + Number(b['Everbright TPO Baseline']).toFixed(2) : '--'}</TableCell>
                    <TableCell>{b['Utility Company for Enfin TPO']}</TableCell>
                    <TableCell>{b['Sunnova TPO Baseline']}</TableCell>
                  </TableRow>
                ))}
              </Table>
            )}
          </Box>
        </Box>
      </Dialog>
    </div>
  );
};

export default BaselineModal;
